import api from "@/services/api";
import AppError from "@/utils/appError";

const resource = "empresas";

const buscarDocumentosPME = async ({ idProposta, trazArquivos }) => {
  try {
    const config = { 
      headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_API_KEY_EMPRESAS },
      params: {
        trazArquivos
      }
    };
    const { data } = await api.get(`${resource}/${idProposta}/documentos`, config);
    return data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

const adicionarDocumento = async ({
    idProposta, guidTipoDocumento, arquivos, pagesSelected,
  }) => {
    try {
      const body = {
        guidTipoDocumento,
        arquivos,
        paginasSelecionadas: pagesSelected,
        origem: "CORRETOR",
        validaDocumento: true
      };
      const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_API_KEY_EMPRESAS } };
      const { data } = await api.post(`${resource}/${idProposta}/documentos`, body, config);
      return data;
    } catch (error) {
      const { response } = error;
      throw new AppError(response, 400);
    }
};

const excluirDocumento = async ({
  idProposta,
  arquivos,
}) => {
  const body = {
    arquivos,
  };
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_API_KEY_EMPRESAS } };
    const { data } = await api.post(`${resource}/${idProposta}/documentos/excluir`, body, config);
    return data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

export default {
  buscarDocumentosPME,
  adicionarDocumento,
  excluirDocumento,
};
