var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-app-bar",
        { attrs: { color: "primary", dark: "", fixed: "" } },
        [
          _c(
            "v-container",
            { staticClass: "d-flex align-center pa-0" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mr-3",
                  attrs: { small: "", icon: "", dark: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("back")
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-chevron-left")])],
                1
              ),
              _c("v-toolbar-title", { staticClass: "mt-1" }, [
                _c("div", [
                  _c(
                    "h1",
                    {
                      staticClass: "title font-weight-bold mb-1 title",
                      staticStyle: { "line-height": "1" },
                    },
                    [_vm._v("Documentos")]
                  ),
                  _c(
                    "h3",
                    {
                      staticClass: "caption ma-0",
                      staticStyle: { "line-height": "1" },
                    },
                    [_vm._v("Dados contratação")]
                  ),
                ]),
              ]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { small: "", icon: "", dark: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("openmenu")
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-menu")])],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "header-height" }),
      _c("v-container", { staticClass: "pt-2" }, [
        _vm.loadingDocs
          ? _c(
              "div",
              { staticClass: "d-flex justify-center mt-10 pa-10" },
              [
                _c("v-progress-circular", {
                  attrs: { indeterminate: "", color: "grey", size: "50" },
                }),
              ],
              1
            )
          : !_vm.loadingDocs && _vm.documentos.length > 0
          ? _c(
              "div",
              [
                _c(
                  "v-row",
                  _vm._l(_vm.documentos, function (documento, index) {
                    return _c(
                      "v-col",
                      {
                        key: index,
                        staticClass: "pa-1",
                        attrs: { cols: "6", md: "4" },
                      },
                      [
                        _c("base-card-document", {
                          attrs: {
                            type: _vm.formataTipoBeneficiario(
                              documento.tipoBeneficiario
                            ),
                            name: _vm._f("firstName")(
                              documento.beneficiario.nome
                            ),
                            gender: documento.beneficiario.sexo,
                            age: _vm._f("getAge")(
                              documento.beneficiario.dataNascimento
                            ),
                            status: _vm.getStatus(index),
                            dark:
                              documento.tipoBeneficiario === "TITULAR"
                                ? true
                                : false,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.openDialog(
                                index,
                                documento.tipoBeneficiario
                              )
                            },
                          },
                        }),
                      ],
                      1
                    )
                  }),
                  1
                ),
                _c(
                  "v-dialog",
                  {
                    attrs: {
                      "retain-focus": false,
                      "no-click-animation": "",
                      persistent: "",
                      fullscreen: "",
                      "hide-overlay": "",
                      transition: "dialog-bottom-transition",
                    },
                    model: {
                      value: _vm.dialog.show,
                      callback: function ($$v) {
                        _vm.$set(_vm.dialog, "show", $$v)
                      },
                      expression: "dialog.show",
                    },
                  },
                  [
                    _vm.dialog.documentos
                      ? _c(
                          "v-card",
                          [
                            _c("content-documents", {
                              attrs: { param: _vm.dialog.documentos },
                              on: {
                                closedialog: function ($event) {
                                  _vm.dialog.show = false
                                },
                                "update:param": function ($event) {
                                  return _vm.$set(
                                    _vm.dialog,
                                    "documentos",
                                    $event
                                  )
                                },
                                update: _vm.carregarDocumentos,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "v-row",
                  { attrs: { justify: "center" } },
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "6" } },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass:
                              "secundaryBtn--text mt-6 mb-4 mt-md-10",
                            attrs: {
                              large: "",
                              "data-test-id": "ver-resumo",
                              elevation: "10",
                              block: "",
                              color: "primary",
                            },
                            on: { click: _vm.onClickSalvar },
                          },
                          [_vm._v(" Salvar e continuar ")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "300", eager: "", persistent: "" },
          model: {
            value: _vm.dialogConfirmacaoNumero,
            callback: function ($$v) {
              _vm.dialogConfirmacaoNumero = $$v
            },
            expression: "dialogConfirmacaoNumero",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "pa-0" },
            [
              _c(
                "v-sheet",
                {
                  staticStyle: {
                    "border-bottom-right-radius": "0",
                    "border-bottom-left-radius": "0",
                  },
                  attrs: { color: "primary pa-2" },
                },
                [
                  _c("h2", { staticClass: "white--text title text-center" }, [
                    _vm._v("Enviar confirmação de número"),
                  ]),
                ]
              ),
              _c("v-sheet", { staticClass: "pa-4 pt-6 pb-0" }, [
                _c("p", { staticClass: "subtitle-2 text-center mb-3" }, [
                  _vm._v(
                    " O preenchimento da declaração de saúde é liberado apenas após a confirmação dos dados informados pelo cliente, isso é feito através deste código de segurança. "
                  ),
                ]),
                _c(
                  "p",
                  { staticClass: "subtitle-2 text-center primary--text mb-5" },
                  [
                    _c("b", [_vm._v("O código será enviado para")]),
                    _c("br"),
                    _vm._v(" >"),
                    _c("br"),
                    _c("b", [_vm._v("no número")]),
                    _c("br"),
                  ]
                ),
              ]),
              _c("v-divider"),
              _c(
                "v-sheet",
                { staticClass: "d-flex", attrs: { color: "pa-2" } },
                [_c("v-spacer")],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: {
            "retain-focus": false,
            "no-click-animation": "",
            persistent: "",
            fullscreen: "",
            "hide-overlay": "",
            transition: "dialog-bottom-transition",
          },
          model: {
            value: _vm.dialogValidacao,
            callback: function ($$v) {
              _vm.dialogValidacao = $$v
            },
            expression: "dialogValidacao",
          },
        },
        [
          _c(
            "v-card",
            { attrs: { tile: "" } },
            [
              _c(_vm.pages.ValidacaoDadosDialog, {
                ref: "component",
                tag: "component",
                attrs: { data: _vm.dadosValidacao, origem: "fluxo" },
                on: {
                  closedialog: _vm.closeDialogValidacao,
                  next: function ($event) {
                    return _vm.$emit("gotodadosproposta")
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "450", eager: "" },
          model: {
            value: _vm.dialogAnexo,
            callback: function ($$v) {
              _vm.dialogAnexo = $$v
            },
            expression: "dialogAnexo",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "pa-0" },
            [
              _c(
                "v-sheet",
                {
                  staticStyle: {
                    "border-bottom-right-radius": "0",
                    "border-bottom-left-radius": "0",
                  },
                  attrs: { color: "primary pa-2" },
                },
                [
                  _c("h2", { staticClass: "white--text title text-center" }, [
                    _vm._v("Aviso"),
                  ]),
                ]
              ),
              _c("v-sheet", { staticClass: "pa-4 pt-6 pb-0" }, [
                _c("p", { staticClass: "subtitle-2 text-center mb-3" }, [
                  _vm._v(
                    "A inclusão de anexos após a assinatura do cliente acarretará na assinatura de um adendo concordando com a nova documentação inserida no contrato."
                  ),
                ]),
              ]),
              _c("v-divider"),
              _c(
                "v-sheet",
                { staticClass: "d-flex", attrs: { color: "pa-2" } },
                [_c("v-spacer")],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }