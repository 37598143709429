<template>
  <div>
    <v-app-bar color="primary" dark fixed>
      <v-container class="d-flex align-center pa-0">
        <v-btn class="mr-3" small icon dark @click="$emit('back')">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-toolbar-title class="mt-1">
          <div>
            <h1 class="title font-weight-bold title" style="line-height: 1.2">
              Vigência
            </h1>
            <h3 class="caption ma-0" style="line-height: 1.2">
              Dados contratação
            </h3>
          </div>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn small icon dark @click="$emit('openmenu')">
          <v-icon>mdi-menu</v-icon>
        </v-btn>
      </v-container>
    </v-app-bar>
    <div class="header-height"></div>
    <v-container class="pa-5" v-if="vigencias.length">
      <div class="d-flex justify-center mt-10 pa-10" v-if="loading">
        <v-progress-circular indeterminate color="grey" size="50"></v-progress-circular>
      </div>
      <div v-else>
        <v-row justify="center">
          <v-col class="col-12 col-md-6">
            <!-- <h2 class="primary--text mb-2 subtitle-1 font-weight-bold">Vigência selecionada</h2>
        <div class="pa-4 pt-0 pl-0 pr-0 d-flex align-center" style="min-height: 110px">
           <base-button-validity
              class="col"
              v-if="vigenciaSelecionada"
              :dataVigencia="vigenciaSelecionada.dataVigencia"
              :dataAceitacao="vigenciaSelecionada.fechamentoAceitacao"
              :dataRepresentacao="vigenciaSelecionada.fechamentoRepresentacao"
              @click="select(vigenciaSelecionada)"
            ></base-button-validity>
            <p v-else class="grey--text ma-0 text-center col">Nenhuma vigência selecionada</p>
        </div>
            <v-divider color="primary"></v-divider>-->
            <modal-confirmation
              :show="cancelarPropostaHerdada.showConfirm"
              name="cancelar"
              :loading="cancelarPropostaHerdada.loading"
              :title="cancelarPropostaHerdada.title"
              :text="cancelarPropostaHerdada.text"
              :buttonConfirm="cancelarPropostaHerdada.confirm"
              :buttonCancel="cancelarPropostaHerdada.cancel"
              :width="500"
              @close="cancelarPropostaHerdada.callAfterCancel"
              @confirm="cancelarPropostaHerdada.callAfterConfirm"
            />
            <v-row>
              <v-col cols="12" v-for="(vigencia, i) in vigencias" :key="vigencia.id">
                <!-- v-if="!vigenciaSelected || vigencia.dataVigencia != vigenciaSelected.dataVigencia" -->
                <base-button-validity
                  :selected="vigenciaSelected ? vigencia.dataVigencia === vigenciaSelected.dataVigencia : false"
                  :especial="vigencia.herdouDataVigencia || vigencia.vigenciaSolicitada || false"
                  :observacao="retornaMensagemVigencia(vigencia)"
                  :dataVigencia="vigencia.dataVigencia"
                  :dataAceitacao="vigencia.dataFechamentoAceitacao"
                  :dataRepresentacao="vigencia.dataFechamentoReapresentacao"
                  :loadingSelecionar="loadingSelecionar[i].lSelec"
                  :data-test-id="'vigencia-' + i"
                  @selecionar="selecionarVigencia(vigencia, i)"
                ></base-button-validity>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </v-container>
    <div v-else class="mt-3">
      <div class="d-flex justify-center mt-10 pa-10" v-if="loading">
        <v-progress-circular indeterminate color="grey" size="50"></v-progress-circular>
      </div>
      <div v-else>
        <p class="text-center">Nenhuma vigência encontrada</p>
      </div>
    </div>
    <v-bottom-navigation :height="70" light scroll-threshold fixed v-if="vigenciaSelected">
      <v-row align-content="end">
        <v-col xs="2" sm="2" md="2" lg="2" xl="2" class="pa-6">
          <p>Vigência selecionada:</p>
        </v-col>
        <v-col xs="10" sm="6" md="3" lg="3" xl="3" class="pa-2">
          <v-list-item>
            <v-list-item-icon class="d-flex align-center justify-center mr-2">
              <v-icon>mdi-calendar</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="subtitle-2 font-weight-bold d-flex align-center ma-0">
                <span class="mr-2">Início vigência</span>
                <v-spacer></v-spacer>
                <span>{{ vigenciaSelected.dataVigencia | convertDateOutput }}</span>
              </v-list-item-title>
              <v-list-item-subtitle class="caption d-flex align-center ma-0">
                <span>Comercialização</span>
                <v-spacer></v-spacer>
                <span>{{ vigenciaSelected.dataFechamentoAceitacao | convertDateOutput }}</span>
              </v-list-item-subtitle>
              <v-list-item-subtitle class="caption d-flex align-center ma-0">
                <span>Reapresentação</span>
                <v-spacer></v-spacer>
                <span>{{ vigenciaSelected.dataFechamentoReapresentacao | convertDateOutput }}</span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
    </v-bottom-navigation>
  </div>
</template>

<script>
/* eslint-disable */
import { mapState, mapActions } from "vuex";
import BaseButtonValidity from "@/components/BaseButtonValidity.vue";
import vigenciaService from "@/services/vigenciaService";
import propostaService from "@/services/propostaService";
import filters from "@/filters";
import { getPlanoSimilar } from "@/mixins/getPlanoSimilar";
import ModalConfirmation from "@/components/ModalConfirmation.vue";
import { ENUM_PROPOSTAS_FLUXO_ANTIGO } from "@/utils/constants/index";
import empresaService from "@/services/empresaService";

export default {
  name: "Vigencias",
  components: { BaseButtonValidity, ModalConfirmation },
  mixins: [getPlanoSimilar],
  data() {
    return {
      loadingSelecionar: [],
      loading: false,
      vigencias: [],
      vigenciaSelecionada: null,
      propostasHerdadas: [],
      cancelarPropostaHerdada: {
        showConfirm: false,
        title: "Cancelar Proposta Devolvida",
        text: "Deseja cancelar a proposta devolvida?",
        cancel: "Cancelar Proposta",
        confirm: "Retornar Para Seleção",
        aceitouCancelar: undefined,
        callAfterConfirm: () => {},
        callAfterCancel: () => {},
        loading: false,
      },
      resetPlano: undefined,
    };
  },
  computed: {
    ...mapState({
      PMEState: (state) => state.PME,
      propostaState: (state) => state.cadastroProposta,
    }),
    vigenciaSelected() {
      return this.vigencias.find((item) => item.dataVigencia === this.PMEState.vigencia);
    },
    needResetPlano() {
      return (this.propostaState.plano
        && this.propostaState.plano.idProdutoFatura
        && !ENUM_PROPOSTAS_FLUXO_ANTIGO.includes(this.propostaState.inicializada)
        && this.propostaState.status !== "DEVOLVIDA")
        || this.blnContinuarState.planoNaoComercializado;
    },
  },
  methods: {
    ...mapActions({
      setVigenciaPme: "PME/setVigencia",
      setVigencia: "cadastroProposta/setVigencia",
      setCorretora: "cadastroProposta/setCorretora",
      setOperadora: "cadastroProposta/setOperadora",
      setDadosProposta: "cadastroProposta/setDadosProposta",
      setDadosAdministradora: "cadastroProposta/setDadosAdministradora",
      verificaReajustePlano: "cadastroProposta/setVerificarReajustePlano",
    }),
    async showModalCancelarPropostaHerdada(vigencia, i) {
      this.cancelarPropostaHerdada.showConfirm = true;
      this.cancelarPropostaHerdada.confirm = "Seguir Sem Cancelar";
      this.cancelarPropostaHerdada.cancel = "Cancelar Proposta";
      this.cancelarPropostaHerdada.callAfterConfirm = this.seguirSemCancelarHerdada;
      this.cancelarPropostaHerdada.callAfterCancel = this.aceitouCancelarHerdada;
      this.cancelarPropostaHerdada.text = `Deseja cancelar a proposta devolvida (${this.propostaHerdada.nrProposta}) deste mesmo titular?`;
      if (vigencia.herdouDataVigencia) {
        // eslint-disable-next-line max-len
        this.cancelarPropostaHerdada.text = `Para prosseguir com essa vigência, é necessário cancelar a proposta (${this.propostaHerdada.nrProposta}) devolvida associada a este titular e vigência.`;
        this.cancelarPropostaHerdada.confirm = "Retornar Para Seleção";
        this.cancelarPropostaHerdada.callAfterConfirm = this.closeModalCancelarPropostaHerdada;
      }
    },
    async closeModalCancelarPropostaHerdada() {
      this.cancelarPropostaHerdada.aceitouCancelar = undefined;
      this.cancelarPropostaHerdada.showConfirm = false;
    },
    seguirSemCancelarHerdada() {
      this.cancelarPropostaHerdada.aceitouCancelar = false;
      this.prosseguir();
      this.cancelarPropostaHerdada.showConfirm = false;
    },
    async aceitouCancelarHerdada() {
      this.cancelarPropostaHerdada.aceitouCancelar = true;
      const textoAlerta = `Confirmar o cancelamento da proposta devolvida número ${this.propostaHerdada.nrProposta}?`;
      const confirmouCancelar = await this.$root.$confirmdialog.open("Confirmar cancelamento", textoAlerta);
      if (!confirmouCancelar) return;
      this.prosseguir();
      this.cancelarPropostaHerdada.showConfirm = false;
    },
    async getPropostaHerdada() {
      try {
        const res = await propostaService.getPropostaHerdada({ cpfTitular: this.propostaState.titular.cpf });

        const { data: response } = res;
        const { data: propostasHerdadas } = response;
        this.propostasHerdadas = propostasHerdadas || [];
      } catch (error) {
        this.$root.$snackBar.open({
          color: "error",
          message: error.message || "Erro ao buscar propostas herdadas, tente novamente mais tarde",
        });
      }
    },
    async tratarVigenciaHerdada() {
      if (this.propostaState?.titular?.cpf || false) {
        await this.getPropostaHerdada();
      }

      if (this.propostasHerdadas && this.propostasHerdadas.length) {
        const [propostaHerdada] = this.propostasHerdadas
          .filter((prop) => (prop.status.toLowerCase() === "devolvida" || prop.status.toLowerCase() === "cancelada") && prop.guid !== this.propostaState.guid)
          .sort((a, b) => {
            if (b.dataFechamentoReapresentacao < a.dataFechamentoReapresentacao) {
              return -1;
            }
            if (b.dataFechamentoReapresentacao > a.dataFechamentoReapresentacao) {
              return 1;
            }
            return 0;
          });
        this.propostaHerdada = propostaHerdada;
        this.dataVigenciaHerdada = (propostaHerdada && propostaHerdada.dataVigencia) || undefined;
      }
    },
    async getVigencias() {
      try {
        this.loading = true;

        const body = {
          entidade: process.env.VUE_APP_ENTIDADE_PME,
          operadora: this.PMEState.operadora.nome,
          uf: this.PMEState.empresa.enderecoEmpresa.estado,
          cidade: this.PMEState.empresa.enderecoEmpresa.cidade,
          cpfCnpjCorretora: this.PMEState.corretora.corretora.cpfCnpj,
          dataVigenciaHerdada: null,
          beneficiarios: [],
          portabilidade: false,
          cpfTitular: null,
          cnpjOperadora: this.PMEState.operadora.id.toString(),
        };

        const response = await vigenciaService.obterVigenciaPorOperadora(body);

        response.forEach((item, i) => this.loadingSelecionar.push({ lSelec: false }));
        this.vigencias = response;
      } catch (error) {
        this.$root.$snackBar.open({ color: 'error', message: error.message });
      } finally {
        this.loading = false;
      }
    },

    async selecionarVigencia(vigencia, i) {
      const semPlano = !this.propostaState.plano || !this.propostaState.plano.idProdutoFatura;

      // nao envia para plano se nao foi resetado o plano/possui comercializacao/nao entrou na vigencia a partir da edicao do plano
      // const naoEnviaParaPlano = !(this.needResetPlano || (semPlano || this.blnContinuarState.isEditingPlan));
      const naoEnviaParaPlano = !(false || (semPlano || (this.blnContinuarState && this.blnContinuarState.isEditingPlan)));
      // regra para fechar a tela ao selecionar a mesma vigencia
      let closeSameVigencia = false;
      if (this.propostaState.status === "DEVOLVIDA") {
        // apenas fecha tela caso nao precise ir para tela de plano - fluxo de devolvida
        closeSameVigencia = naoEnviaParaPlano;
      } else {
        closeSameVigencia = true;
      }
      this.vigenciaSelecionada = { index: i, vigencia };
      if (vigencia.dataVigencia === this.dataVigenciaState && closeSameVigencia) {
        this.$emit("next");
        return;
      }

      const herdouDataVigencia = !!(this.vigencias.find((vig) => vig.herdouDataVigencia));
      if (this.dataVigenciaHerdada && this.propostaHerdada && herdouDataVigencia) {
        if (this.propostaHerdada.status !== 'CANCELADA') {
          this.showModalCancelarPropostaHerdada(vigencia, i);
          return;
        }
      }

      this.prosseguir();
    },
    async prosseguir() {
      const { index: i, vigencia } = this.vigenciaSelecionada;

      const textoAlerta = `Confirmar a data de vigência <b>${filters.convertDateOutput(vigencia.dataVigencia)}?`;
      
      const resp = await this.$root.$confirmdialog.open("Seleção vigência", textoAlerta);

      if (!resp) return;

      // if (alertaResetPlano) {
      //   const { operadora } = this.propostaState.plano;
      //   await this.resetDadosPlano({ operadora });
      //   this.resetPlano = true;
      // }

      this.loadingSelecionar[i].lSelec = true;
      const respReajuste = await this.verificarCartaReajuste(vigencia);
      if (!respReajuste) {
        this.loadingSelecionar[i].lSelec = false;
        return;
      }

      try {
        this.loadingSelecionar[i].lSelec = true;
        const dataVigencia = filters.convertDateInput(vigencia.dataVigencia);
      
        this.setVigenciaPme({dataVigencia, dataFechamentoAceitacao: vigencia.dataFechamentoAceitacao, dataFechamentoReapresentacao: vigencia.dataFechamentoReapresentacao})
        this.setVigencia({dataVigencia, dataFechamentoAceitacao: vigencia.dataFechamentoAceitacao, dataFechamentoReapresentacao: vigencia.dataFechamentoReapresentacao})
        this.setOperadora(this.PMEState.operadora)
        this.setCorretora({corretora: this.PMEState.corretora.corretora})
        this.setDadosAdministradora(this.PMEState.corretora.corretora.administradoras)
        this.setDadosProposta()
        await empresaService.atualizarVigencia({
          id: this.PMEState.id,
          vigencia: dataVigencia,
          dataFechamentoAceitacao: vigencia.dataFechamentoAceitacao,
          dataFechamentoReapresentacao: vigencia.dataFechamentoReapresentacao
        })
        this.$emit("next");
      } catch (error) {
        this.$root.$snackBar.open({
          color: "error",
          message: error.message || "Erro ao salvar dados, tente novamente mais tarde",
        });
      } finally {
        this.loadingSelecionar[i].lSelec = false;
      }
    },
    async verificarCartaReajuste(vigencia) {
      const dataVigenciaSelecionada = vigencia.dataVigencia;
      const resp = await this.verificaReajustePlano(dataVigenciaSelecionada);
      if (!resp && !this.blnContinuarState.isEditingPlan) {
        const textReajuste = await this.$root.$confirmdialog.open("Atenção", `O plano selecionado sofrerá reajuste na data de vigência escolhida.`);
        return textReajuste;
      }
      return true;
    },
    retornaMensagemVigencia(vigencia) {
      if (vigencia.herdouDataVigencia) {
        return "Encontrada proposta com vigência válida para o CPF informado";
      } if (vigencia.vigenciaSolicitada && this.propostaState.status !== "DEVOLVIDA") {
        return "Encontrada vigência solicitada para esse CPF";
      } if (vigencia.vigenciaSolicitada && this.propostaState.status === "DEVOLVIDA") {
        return "Proposta com vigência válida para proposta informada";
      }
      return null;
    },
  },
  async mounted() {
    this.loading = true;
    await this.tratarVigenciaHerdada();
    await this.getVigencias();
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
.header-height {
  padding: 20px;
  height: 70px;
}
</style>
