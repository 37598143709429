<template>
  <div>
    <v-app-bar color="primary" dark fixed>
      <v-container class="d-flex align-center pa-0">
        <v-btn class="mr-3" small icon dark @click="$emit('back')">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-toolbar-title class="mt-1">
          <div>
            <h1 class="title font-weight-bold mb-1 title" style="line-height: 1">Dados financeiros</h1>
            <h3 class="caption ma-0" style="line-height: 1">Dados contratação</h3>
          </div>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn small icon dark @click="$emit('openmenu')">
          <v-icon>mdi-menu</v-icon>
        </v-btn>
      </v-container>
    </v-app-bar>
    <div class="header-height"></div>
    <v-container class="pa-5 pt-4">
      <v-row justify="center">
        <v-col xs="12" sm="12" md="6" lg="6" xl="6">
          <validation-observer ref="form" tag="form" @submit.prevent="submit">
            <h2 class="primary--text mb-2 subtitle-1 font-weight-bold">Forma de pagamento</h2>
            <v-divider></v-divider>
            <validation-provider name="Forma de pagamento" vid="formaPagamento" slim v-slot="{ errors }" rules="required">
              <v-radio-group v-model="form.formaPagamento" :mandatory="false" :error-messages="errors">
                <div v-for="(formaPagamento, index) in formasPagamento" :key="index">
                  <v-radio :data-test-id="'forma-pagamento-' + index" class="mb-4 mt-4" :label="formaPagamento.nome" :value="formaPagamento.id"></v-radio>
                  <v-divider v-if="index < formasPagamento.length - 1"></v-divider>
                </div>
              </v-radio-group>
            </validation-provider>
            <div v-if="mostrarOpcoesTaxa">
              <h2 class="primary--text mb-2 subtitle-1 font-weight-bold">Taxa de angariação</h2>
              <v-divider></v-divider>
              <div class="taxa-slider mt-10">
                <div class="text-center primary--text mb-10"><strong>Selecione na barra o valor de desconto</strong></div>
                <v-slider v-model="form.porcentagemTaxaAngariacao" :max="95" thumb-label="always">
                  <template v-slot:thumb-label="{ value }">
                    <div class="taxa-slider__porcentagem">{{ value }}%</div>
                  </template>
                </v-slider>
              </div>
            </div>
            <div class="text-center" v-if="loading">
              <v-progress-circular indeterminate color="primary"></v-progress-circular>
            </div>
          </validation-observer>
          <v-btn :data-test-id="'salvar-continuar-7'" large elevation="10" block :disabled="loading" :loading="validating" color="primary" class="secundaryBtn--text mt-4 mb-4" @click="submit">
            Salvar e continuar
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import pagamentosService from "@/services/pagamentosService";
import empresaService from "@/services/empresaService";

export default {
  name: "DadosFinanceiros",
  data() {
    return {
      loading: false,
      formasPagamento: [],
      form: {
        formaPagamento: null,
        porcentagemTaxaAngariacao: 0,
      },
      validating: false,
    };
  },
  mounted() {
    this.getFormasPagamento();
    this.setPreviousData();
  },
  computed: {
    ...mapState({
      PMEState: (state) => state.PME,
      propostaState: (state) => state.cadastroProposta
    }),
    mostrarOpcoesTaxa() {
      const { corretora: corretoraState } = this.PMEState;
      if (corretoraState && corretoraState.corretora) {
        const { corretora } = corretoraState;
        return corretora.taxaDefinidaPeloCorretor && corretora.necessarioAnexoTaxaAngariacao;
      }
      return false;
    },
  },
  watch: {},
  methods: {
    ...mapActions({
      setFinanceiro: "PME/setFinanceiro",
    }),
    setPreviousData() {
      if (this.PMEState.financeiro) {
        this.form = {
          ...this.form,
          ...this.PMEState.financeiro,
        };
      }
    },
    async getFormasPagamento() {
      try {
        this.loading = true;
        const data = await pagamentosService.getFormasPagamentoPME();
        this.formasPagamento = data;
      } catch (error) {
        this.mostraErro(error.message || "Erro ao buscar dados, tente novamente mais tarde");
        this.$emit("error");
        return error;
      } finally {
        this.loading = false;
      }
    },
    mostraErro(mensagem) {
      this.$root.$snackBar.open({
        color: "error",
        message: mensagem,
      });
      this.$emit("error");
    },
    async submit() {
      const isValid = await this.$refs.form.validate();
      if (!isValid) {
        this.$root.$snackBar.open({
          color: "error",
          message: "Verifique o preenchimento do formulário e tente novamente",
        });
        return;
      }
      this.validating = true;

      const dadosFinanceiros = this.$cleanObserver(this.form);

      this.setFinanceiro(dadosFinanceiros);

      try {
        const body = {
          ...dadosFinanceiros,
        };
        await empresaService.atualizarDadosFinanceiros({id: this.PMEState.id, body});
        this.$emit("next");
      } catch (error) {
        this.mostraErro(error.message || "Erro ao salvar dados, tente novamente mais tarde");
      } finally {
        this.validating = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.header-height {
  padding: 20px;
  height: 70px;
}
</style>
