<template>
  <div>
    <v-app-bar color="primary" dark fixed>
      <v-container class="d-flex align-center pa-0">
        <v-btn class="mr-3" small icon dark @click="$emit('back')">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-toolbar-title class="mt-1">
          <div>
            <h1 class="title font-weight-bold mb-1 title" style="line-height: 1">Documentos</h1>
            <h3 class="caption ma-0" style="line-height: 1">Dados contratação</h3>
          </div>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn small icon dark @click="$emit('openmenu')">
          <v-icon>mdi-menu</v-icon>
        </v-btn>
      </v-container>
    </v-app-bar>
    <div class="header-height"></div>
    <v-container class="pt-2">
      <div class="d-flex justify-center mt-10 pa-10" v-if="loadingDocs">
        <v-progress-circular indeterminate color="grey" size="50"></v-progress-circular>
      </div>
      <div v-else-if="!loadingDocs && documentos.length > 0">
        <v-row>
          <v-col cols="6" md="4" class="pa-1" v-for="(documento, index) in documentos" :key="index">
            <base-card-document
              :type="formataTipoBeneficiario(documento.tipoBeneficiario)"
              :name="documento.beneficiario.nome | firstName"
              :gender="documento.beneficiario.sexo"
              :age="documento.beneficiario.dataNascimento | getAge"
              :status="getStatus(index)"
              @click="openDialog(index, documento.tipoBeneficiario)"
              :dark="documento.tipoBeneficiario === 'TITULAR' ? true : false"
            ></base-card-document>
          </v-col>
        </v-row>
        <v-dialog :retain-focus="false" no-click-animation v-model="dialog.show" persistent fullscreen hide-overlay transition="dialog-bottom-transition">
          <v-card v-if="dialog.documentos">
            <content-documents @closedialog="dialog.show = false" :param.sync="dialog.documentos" @update="carregarDocumentos"></content-documents>
          </v-card>
        </v-dialog>
        <v-row justify="center">
          <v-col cols="6">
            <v-btn large :data-test-id="'ver-resumo'" elevation="10" block color="primary" class="secundaryBtn--text mt-6 mb-4 mt-md-10" @click="onClickSalvar"> Salvar e continuar </v-btn>
          </v-col>
        </v-row>
      </div>
    </v-container>
    <v-dialog v-model="dialogConfirmacaoNumero" max-width="300" eager persistent>
      <v-card class="pa-0">
        <v-sheet color="primary pa-2" style="border-bottom-right-radius: 0; border-bottom-left-radius: 0">
          <h2 class="white--text title text-center">Enviar confirmação de número</h2>
        </v-sheet>
        <v-sheet class="pa-4 pt-6 pb-0">
          <p class="subtitle-2 text-center mb-3">
            O preenchimento da declaração de saúde é liberado apenas após a confirmação dos dados informados pelo cliente, isso é feito através deste código de segurança.
          </p>
          <p class="subtitle-2 text-center primary--text mb-5">
            <b>O código será enviado para</b><br />
            <!-- <span class="text-uppercase">{{ dadosResponsavelState ? dadosResponsavelState.nome : titular.nome }}</span -->
            ><br />
            <b>no número</b><br />
            <!-- ({{ celularContato.numero.substring(0, 2) }}) {{ celularContato.numero.substring(2, 7) }}-{{ celularContato.numero.substring(7, 11) }} -->
          </p>
        </v-sheet>
        <v-divider></v-divider>
        <v-sheet color="pa-2" class="d-flex">
          <!-- <v-btn :data-test-id="'enviar-mais-tarde'" text color="primary" :disabled="loadingSms" :loading="loadingMaisTarde" @click="enviarMaisTarde">
            <span class="font-weight-bold text-capitalize subtitle-1 grey--text">Mais tarde</span>
          </v-btn> -->
          <v-spacer></v-spacer>
          <!-- <v-btn :data-test-id="'enviar-confirmacao-agora'" text color="primary" @click="dialogAnexo = true">
            <span class="font-weight-bold text-capitalize subtitle-1 blue--text">Enviar agora</span>
          </v-btn> -->
        </v-sheet>
      </v-card>
    </v-dialog>
    <v-dialog :retain-focus="false" no-click-animation v-model="dialogValidacao" persistent fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card tile>
        <component ref="component" :is="pages.ValidacaoDadosDialog" @closedialog="closeDialogValidacao" @next="$emit('gotodadosproposta')" :data="dadosValidacao" :origem="'fluxo'"></component>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogAnexo" max-width="450" eager>
      <v-card class="pa-0">
        <v-sheet color="primary pa-2" style="border-bottom-right-radius: 0; border-bottom-left-radius: 0">
          <h2 class="white--text title text-center">Aviso</h2>
        </v-sheet>
        <v-sheet class="pa-4 pt-6 pb-0">
          <p class="subtitle-2 text-center mb-3">A inclusão de anexos após a assinatura do cliente acarretará na assinatura de um adendo concordando com a nova documentação inserida no contrato.</p>
        </v-sheet>
        <v-divider></v-divider>
        <v-sheet color="pa-2" class="d-flex">
          <!-- <v-btn :data-test-id="'voltar-para-anexo'" text color="primary" :disabled="loadingSms" @click="onClickVoltarAnexo">
            <span class="font-weight-bold text-capitalize subtitle-1 grey--text">
              {{ $vuetify.breakpoint.xs ? 'Voltar' : 'Voltar para anexo' }}
            </span>
          </v-btn> -->
          <v-spacer></v-spacer>
        </v-sheet>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import BaseCardDocument from "@/components/BaseCardDocument.vue";
import documentoService from "@/services/documentosPropostaPMEService";
import ContentDocuments from "./ContentDocuments.vue";
import propostaService from "@/services/propostaService";
import ValidacaoDadosDialog from "@/views/AreaLogada/CriarProposta/ValidacaoDados/index.vue";
import { Capacitor } from '@capacitor/core';
import { Geolocation } from '@capacitor/geolocation';
import empresaService from "@/services/empresaService";
import corretoraService from "@/services/corretoraService";

export default {
  name: "Documentos",
  components: { BaseCardDocument, ContentDocuments },
  data() {
    return {
      guid: null,
      guidTitular: null,
      loadingDocs: true,
      dialog: {
        tipo: "titular",
        id: 0,
        show: false,
        documentos: {},
      },
      dialogTipo: null,
      dialogConfirmacaoNumero: false,
      dialogValidacao: false,
      dialogAnexo: false,
      documentos: [],
      beneficiarios: [],
      titular: {},
      loadingSms: false,
      loadingMaisTarde: false,
      dadosValidacao: {},
      corretoras: [],
      pages: {
        ValidacaoDadosDialog,
      },
    };
  },
  computed: {
    ...mapState({
      PMEState: (state) => state.PME,
    }),
    // celularContato() {
    //   if (this.dadosResponsavelState && this.dadosResponsavelState.telefones) {
    //     return this.dadosResponsavelState.telefones.find((i) => i.tipo.toUpperCase() === "CELULAR" && i.preferencial === true);
    //   }

    //   if (!(this.propostaState && this.propostaState.titular && this.propostaState.titular.telefones)) {
    //     return "";
    //   }

    //   return this.propostaState.titular.telefones.find((i) => i.tipo.toUpperCase() === "CELULAR" && i.preferencial === true);
    // },
  },
  watch: {
    titularState: {
      immediate: true,
      handler: "setDataTitular",
      deep: true,
    },
  },
  async created() {
    // await this.getCorretoras();
    await this.carregarDocumentos();
  },
  methods: {
    ...mapActions({
      setBtnEdit: 'PME/setBtnEdit',

    }),
    // async getCorretoras() {
    //   try {
    //     this.loading = true;
    //     const data = await corretoraService.getCorretorasPorCpf({
    //       cpf: "45212543819",
    //     });
    //     this.corretoras = data;
    //   } catch (error) {
    //     // TODO: Implementar Tratativa de erro
    //   } finally {
    //     this.loading = false;
    //   }
    // },
    getLocation() {
      return new Promise((resolve) => {
        navigator.geolocation.getCurrentPosition(
          (pos) => {
            const { coords } = pos;
            if (coords && coords.latitude && coords.longitude) {
              resolve(JSON.stringify({ latitude: coords.latitude, longitude: coords.longitude }));
            }
          },
          (err) => {
            const { message } = err;
            resolve(JSON.stringify({ error: message }));
          }
        );
      });
    },
    async getLocationApp() {
      try {
        const { coords } = await Geolocation.getCurrentPosition();
        if (coords && coords.latitude && coords.longitude) {
          return JSON.stringify({ latitude: coords.latitude, longitude: coords.longitude });
        }
      } catch (error) {
        const { message } = error;
        return JSON.stringify({ error: message });
      }
    },
    agruparBeneficiarios() {
      this.beneficiarios.push({
        guid: "",
        nome: "Matheus",
        sexo: "Masculino",
        dataNascimento: "08/03/1997",
      });
    },
    async carregarDocumentos(update) {
      try {
        this.loadingDocs = true;
        const { data } = await documentoService.buscarDocumentosPME({
          idProposta: this.PMEState.id,
          trazArquivos: true,
        });
        this.agruparDocumentos([data], update);
        this.loadingDocs = false;
      } catch (error) {
        console.log(error)
        this.$root.$snackBar.open({
          color: "error",
          message: "Erro ao buscar documentos, tente novamente mais tarde",
        });
      } finally {
        this.loadingDocs = false;
      }
    },
    agruparDocumentos(data, update = false) {
      this.documentos = [];
      data.forEach((item, i) => {
        const retorno = {
          guid: item.guid,
          tipoBeneficiario: item.tipoBeneficiario,
          parentesco: item.parentesco,
          tiposDocumentos: [],
          beneficiario: {
            guid: "",
            nome: this.PMEState.empresa.representanteEmpresa.nomeCompleto,
            sexo: this.PMEState.empresa.representanteEmpresa.sexo,
            dataNascimento: this.PMEState.empresa.representanteEmpresa.dataNascimento,
          },
        };
        const { tiposDocumentos } = item;
        const grupos = tiposDocumentos.map(({ grupo }) => grupo);
        const distinctGrupos = grupos.filter((value, index, self) => self.indexOf(value) === index);
        distinctGrupos.forEach((itemDg) => {
          const docs = tiposDocumentos.filter(({ grupo, visivel }) => grupo === itemDg && visivel);
          const { habilitadoUpload } = tiposDocumentos.find((doc) => doc.grupo === itemDg);
          if (docs && docs.length > 0) {
            const arquivosTotal = docs.filter((doc) => doc.arquivos && doc.arquivos.length > 0);
            let preenchido = false;
            if (arquivosTotal.length > 0) preenchido = true;
            if (itemDg !== "Recibo de Taxa de Angariação") {
              retorno.tiposDocumentos.push({
                grupo: itemDg,
                documentos: docs,
                preenchido,
                habilitadoUpload,
              });
            }

            // if(itemDg === "Recibo de Taxa de Angariação" && this.corretoras.filter(c => c.dadosImpressos === "QUALICORP").length) {
            //   retorno.tiposDocumentos.push({
            //     grupo: itemDg,
            //     documentos: docs,
            //     preenchido,
            //     habilitadoUpload,
            //   });
            // }
          }
        });
        this.documentos.push(retorno);
      });
      if (update) {
        this.openDialog(this.dialog.id, this.dialog.tipo);
      }
    },
    openDialog(index, tipo) {
      this.dialog.tipo = tipo;
      this.dialog.id = index;
      const dadosDocumentos = this.documentos[index];
      this.dialog.documentos = {
        beneficiario: dadosDocumentos.beneficiario,
        dadosDocumentos,
        guidProposta: this.guid,
        showBotao: true,
        anexarArquivos: true,
        excluirArquivos: true,
      };
      this.dialog.show = true;
    },
    fecharDialog() {
      this.dialog.id = null;
      this.dialog.show = false;
      this.dialog.documentos = {};
    },
    getStatus(index) {
      const docs = this.documentos[index];
      const docsObrigatorios = docs.tiposDocumentos.filter((doc) => doc.grupo !== "Outros");
      const total = docsObrigatorios.filter((doc) => doc.preenchido);
      return total.length === docsObrigatorios.length ? "preenchido" : "pendente";
    },
    checkAnexoObrigatorioPreenchido() {
      const docArray = [];
      this.documentos.forEach((docs) => {
        const docsObrigatorios = docs.tiposDocumentos.filter((doc) => doc.grupo !== "Outros");
        const totalPreenchido = docsObrigatorios.filter((doc) => doc.preenchido);
        if (totalPreenchido.length) docArray.push(true);
        else docArray.push(false);
      });
      return docArray.every((element) => element === true);
    },
    formataTipoBeneficiario(tipo) {
      tipo = tipo.toLowerCase();
      return tipo;
    },
    setDataTitular() {
      this.titular = this.titularState;
    },
    async salvarDados() {
      await propostaService.addOrEdit(this.propostaState.guid, this.propostaState);
    },
    closeDialogValidacao() {
      this.dialogValidacao = false;
    },
    onClickVoltarAnexo() {
      this.dialogAnexo = false;
      this.dialogConfirmacaoNumero = false;
    },
    async onClickSalvar() {
      try {
        const isValidAnexoObrigatorio = this.checkAnexoObrigatorioPreenchido();
        if(!isValidAnexoObrigatorio) {
          this.$root.$snackBar.open({
            color: "warning",
            message: "Deve ser preenchido ao menos um anexo obrigatório para cada beneficiário",
          });
          return;
        }
        await empresaService.atualizarEtapa({ id: this.PMEState.id, etapa: "inclusao_beneficiarios" });
        this.$router.push({ name: "areaLogada.propostaAdesaoPME", params: { guidPME: this.PMEState.guid } });
        // if(this.PMEState.btnEdit.isEditing && this.PMEState.guid) {
        //   this.setBtnEdit({ flag: false, page: 0, isEditing: false });
        //   this.$router.push({ name: 'areaLogada.dadosPropostaPME', params: { guid: this.PMEState.guid } });
        // } else {
          
        // }
      } catch (error) {
        this.$root.$snackBar.open({
          color: "error",
          message: error.message || "Erro ao salvar dados, tente novamente mais tarde",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.header-height {
  padding: 20px;
  height: 70px;
}
</style>
