var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-app-bar",
        { attrs: { color: "primary", dark: "", fixed: "" } },
        [
          _c(
            "v-container",
            { staticClass: "d-flex align-center pa-0" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mr-3",
                  attrs: { small: "", icon: "", dark: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("back")
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-chevron-left")])],
                1
              ),
              _c("v-toolbar-title", { staticClass: "mt-1" }, [
                _c("div", [
                  _c(
                    "h1",
                    {
                      staticClass: "title font-weight-bold mb-1 title",
                      staticStyle: { "line-height": "1" },
                    },
                    [_vm._v("Dados financeiros")]
                  ),
                  _c(
                    "h3",
                    {
                      staticClass: "caption ma-0",
                      staticStyle: { "line-height": "1" },
                    },
                    [_vm._v("Dados contratação")]
                  ),
                ]),
              ]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { small: "", icon: "", dark: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("openmenu")
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-menu")])],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "header-height" }),
      _c(
        "v-container",
        { staticClass: "pa-5 pt-4" },
        [
          _c(
            "v-row",
            { attrs: { justify: "center" } },
            [
              _c(
                "v-col",
                { attrs: { xs: "12", sm: "12", md: "6", lg: "6", xl: "6" } },
                [
                  _c(
                    "validation-observer",
                    {
                      ref: "form",
                      attrs: { tag: "form" },
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.submit.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c(
                        "h2",
                        {
                          staticClass:
                            "primary--text mb-2 subtitle-1 font-weight-bold",
                        },
                        [_vm._v("Forma de pagamento")]
                      ),
                      _c("v-divider"),
                      _c("validation-provider", {
                        attrs: {
                          name: "Forma de pagamento",
                          vid: "formaPagamento",
                          slim: "",
                          rules: "required",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ errors }) {
                              return [
                                _c(
                                  "v-radio-group",
                                  {
                                    attrs: {
                                      mandatory: false,
                                      "error-messages": errors,
                                    },
                                    model: {
                                      value: _vm.form.formaPagamento,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "formaPagamento",
                                          $$v
                                        )
                                      },
                                      expression: "form.formaPagamento",
                                    },
                                  },
                                  _vm._l(
                                    _vm.formasPagamento,
                                    function (formaPagamento, index) {
                                      return _c(
                                        "div",
                                        { key: index },
                                        [
                                          _c("v-radio", {
                                            staticClass: "mb-4 mt-4",
                                            attrs: {
                                              "data-test-id":
                                                "forma-pagamento-" + index,
                                              label: formaPagamento.nome,
                                              value: formaPagamento.id,
                                            },
                                          }),
                                          index < _vm.formasPagamento.length - 1
                                            ? _c("v-divider")
                                            : _vm._e(),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm.mostrarOpcoesTaxa
                        ? _c(
                            "div",
                            [
                              _c(
                                "h2",
                                {
                                  staticClass:
                                    "primary--text mb-2 subtitle-1 font-weight-bold",
                                },
                                [_vm._v("Taxa de angariação")]
                              ),
                              _c("v-divider"),
                              _c(
                                "div",
                                { staticClass: "taxa-slider mt-10" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "text-center primary--text mb-10",
                                    },
                                    [
                                      _c("strong", [
                                        _vm._v(
                                          "Selecione na barra o valor de desconto"
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c("v-slider", {
                                    attrs: { max: 95, "thumb-label": "always" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "thumb-label",
                                          fn: function ({ value }) {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "taxa-slider__porcentagem",
                                                },
                                                [_vm._v(_vm._s(value) + "%")]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      3043642216
                                    ),
                                    model: {
                                      value: _vm.form.porcentagemTaxaAngariacao,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "porcentagemTaxaAngariacao",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "form.porcentagemTaxaAngariacao",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.loading
                        ? _c(
                            "div",
                            { staticClass: "text-center" },
                            [
                              _c("v-progress-circular", {
                                attrs: { indeterminate: "", color: "primary" },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "secundaryBtn--text mt-4 mb-4",
                      attrs: {
                        "data-test-id": "salvar-continuar-7",
                        large: "",
                        elevation: "10",
                        block: "",
                        disabled: _vm.loading,
                        loading: _vm.validating,
                        color: "primary",
                      },
                      on: { click: _vm.submit },
                    },
                    [_vm._v(" Salvar e continuar ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }