<template>
  <div>
    <v-app-bar color="primary" dark fixed>
      <v-container class="d-flex align-center pa-0">
        <v-btn class="mr-3" small icon dark @click="$emit('back')">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>

        <v-toolbar-title class="mt-1">
          <div>
            <h1 class="title font-weight-bold mb-1 title" style="line-height: 1">Operadora</h1>
            <h3 class="caption ma-0" style="line-height: 1">Dados contratação</h3>
          </div>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn small icon dark @click="$emit('openmenu')">
          <v-icon>mdi-menu</v-icon>
        </v-btn>
      </v-container>
    </v-app-bar>
    <div class="header-height"></div>
    <v-container class="pa-5 pt-0">
      <div class="d-flex justify-center mt-10 pa-10" v-if="loading">
        <v-progress-circular indeterminate color="grey" size="50"></v-progress-circular>
      </div>
      <validation-observer ref="form" tag="form" class="mb-10" @submit.prevent="handleSubmit" v-else>
        <v-row justify="center">
          <v-col class="col-12 col-md-6">
            <v-row v-if="!erro.deuErro">
              <v-col class="pb-0">
                <validation-provider
                  name="Operadora"
                  vid="operadora"
                  v-slot="{errors}"
                  rules="required"
                >
                  <v-radio-group
                    v-model="form.operadora"
                    :mandatory="false"
                    :error-messages="errors"
                    v-if="operadoras.length"
                    :disabled="isDisabled"
                  >
                    <div v-for="(operadora, index) in operadoras" :key="index">
                      <v-radio
                        :data-test-id="'operadora-'+ index"
                        class="mb-4"
                        :class="{'mt-4': index > 0}"
                        :label="operadora.nomeExibicaoOperadora"
                        :value="operadora.nomeExibicaoOperadora"
                      ></v-radio>
                      <v-divider v-if="index < operadoras.length - 1"></v-divider>
                    </div>
                  </v-radio-group>
                  <div v-else>
                    <p class="text-center mt-5">Não existe operadoras cadastradas em sua abrangência.</p>
                  </div>
                </validation-provider>
              </v-col>
            </v-row>
            <div class="mt-3" v-else>
              <div>
                <p class="text-center">{{ erro.mensagem }}</p>
              </div>
              <div class="text-center">
                <v-btn
                    text
                    color="primary"
                    class="secundaryBtn--text"
                    @click="getOperadoras"
                  >
                  Tentar novamente
                </v-btn>
              </div>
            </div>
            <v-row no-gutters>
              <v-col>
                <v-btn
                  :loading="loadingSalvar"
                  :data-test-id="'salvar-continuar-3'"
                  large
                  type="submit"
                  elevation="10"
                  block
                  color="primary"
                  class="secundaryBtn--text mt-4"
                  :disabled="erro.deuErro || disableButton"
                >Salvar e continuar</v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </validation-observer>
    </v-container>
    <modal-confirmation
      :show="showModalExcecao"
      name="modalExcecao"
      :persistent="true"
      :loading="false"
      title="Atenção"
      text="Cliente fora da área de abrangência da sua comercialização para esta operadora, para mais informações procure seu gestor comercial"
      buttonConfirm="Ok"
      buttonCancel=""
      :width="500"
      @confirm="() => showModalExcecao = false"
    />
    <modal-confirmation
      :show="showModalBloqueioSegurosUnimed"
      name="modalBloqueioSegurosUnimed"
      :persistent="true"
      :loading="false"
      title="Atenção"
      text="Não é possível seguir com a operadora de saúde selecionada através de portabilidade."
      buttonConfirm="Ok"
      buttonCancel=""
      :width="500"
      @confirm="showModalBloqueioSegurosUnimed = false"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import catalogoService from '@/services/catalogoService';
import ModalConfirmation from "@/components/ModalConfirmation.vue";
import excecaoComercialService from '@/services/excecaoComercialService';
import empresaService from "@/services/empresaService";

export default {
  name: 'Operadora',
  components: {
    ModalConfirmation,
  },
  data() {
    return {
      showModalExcecao: false,
      showModalBloqueioSegurosUnimed: false,
      isDisabled: false,
      disableButton: false,
      loadingSalvar: false,
      loading: false,
      operadoras: [],
      form: {
        operadora: null,
      },
      erro: {
        deuErro: false,
        mensagem: '',
      },
    };
  },
  computed: {
    ...mapState({
      PMEState: (state) => state.PME,
      user: (state) => state.user.profile,
    }),
    operadoraSelected() {
      return this.operadoras.find((item) => item.nomeExibicaoOperadora === this.form.operadora);
    },
  },
  methods: {
    ...mapActions({
      setOperadora: "PME/setOperadora",
    }),
    async getOperadoras() {
      try {
        this.loading = true;
        this.setErro(false, '');
        let data = await catalogoService.getOperadoraPorProposta({
          publicoAlvo: process.env.VUE_APP_PROFISSAO_PME,
          entidade: process.env.VUE_APP_ENTIDADE_PME,
          uf: this.PMEState.empresa.enderecoEmpresa.estado,
          cidade: this.PMEState.empresa.enderecoEmpresa.cidade,
          cpfCnpj: this.PMEState.corretora.corretora.cpfCnpj,
          administradora: this.PMEState.corretora.corretora.administradoras,
        });
        if (data && data.length) {
          data = [...new Map(data.map((item) => [item.nomeExibicaoOperadora && item.nomeExibicaoOperadora.toLowerCase(), item])).values()];
        }
        this.operadoras = data;
        if (this.operadoras && !this.operadoras.length) {
          this.disableButton = true;
        } else {
          this.disableButton = false;
        }

        if (this.PMEState.operadora) {
          this.form.operadora = this.PMEState.operadora.nomeExibicaoOperadora;
        }
      } catch (error) {
        this.setErro(true, error.message || 'Erro ao buscar operadoras');
      } finally {
        this.loading = false;
      }
    },
    setErro(deuErro, mensagem) {
      this.erro = { deuErro, mensagem };
    },

    async salvarDados(operadora) {
      try {
        const item = this.operadoras.find(item => operadora === item.nome)
        this.setOperadora(item)
        await empresaService.atualizarOperadora({
          id: this.PMEState.id,
          operadora: {
            cnpjOperadora: item.id.toString(),
            nome: item.nome,
            nomeExibicaoOperadora: item.nomeExibicaoOperadora
          }
        })
      } catch (error) {
        throw new Error("Não foi possível salvar operadora")
      }
    },

    async handleSubmit() {
      const isValid = await this.$refs.form.validate();
      if (!isValid) return;      

      const empresaUF = this.PMEState.empresa.enderecoEmpresa.estado
      const corretorUF = this.user.endereco.estado;

      if (empresaUF !== corretorUF) {
        const { segueVendaNacional } = this.PMEState.corretora.corretora;

        if (segueVendaNacional) {
          const { id: idOperadora } = this.operadoraSelected;
          const { restricaoUF } = await excecaoComercialService.listarExcecaoOperadora(idOperadora);
          if (restricaoUF) {
            this.showModalExcecao = true;
            return;
          }
        }
      }

      // const { possuiPortabilidade } = this.propostaState;
      // const { operadora } = this.form;
      // if (possuiPortabilidade && operadora === 'SEGUROS UNIMED') {
      //   this.showModalBloqueioSegurosUnimed = true;
      //   return;
      // }

      // const handleProposta = {
      //   redirectStepPlano(context) {
      //     let plano = {};
      //     /*
      //        compara a operadora selecionada com a possivel que tenha no state,
      //           para salvar os dados do plano.
      //     */
      //     if (((context.planoState && context.planoState.operadora) || '').toUpperCase() === (context.operadoraSelected.nome || '').toUpperCase()) {
      //       plano = { ...context.planoState };
      //     } else {
      //       context.setVigencia({});
      //     }

      //     // seta no state os dados do plano mais o nome da operadora selecionada
      //     context.setPlano({
      //       ...plano,
      //       nomeExibicaoOperadora: context.operadoraSelected.nomeExibicaoOperadora,
      //       cnpjOperadora: context.operadoraSelected.id,
      //     });

      //     // seta no state o nome da operadora selecionada
      //     context.setOperadora(context.operadoraSelected.nome);

      //     /*
      //       caso seja uma proposta em edição não salva a nova etapa
      //       caso seja, salva a etapa "plano" no state
      //     */
      //     if (context.blnContinuarState.isEditing) {
      //       context.setDadosProposta({ ...context.propostaState });
      //     } else {
      //       context.setDadosProposta({
      //         ...context.propostaState,
      //         etapa: 'plano',
      //       });
      //     }
      //   },
      //   redirectStepVigencia(context) {
      //     // seta o valor da operadora selecionada
      //     context.setPlano({
      //       nomeExibicaoOperadora: context.operadoraSelected.nomeExibicaoOperadora,
      //       cnpjOperadora: context.operadoraSelected.id,
      //     });

      //     // seta no state o nome da operadora selecionada
      //     context.setOperadora(context.operadoraSelected.nome);

      //     /*
      //       caso seja uma proposta em edição não salva a nova etapa
      //       caso seja, salva a etapa "vigencia" no state
      //     */
      //     if (context.blnContinuarState.isEditing) {
      //       context.setDadosProposta({ ...context.propostaState });
      //     } else {
      //       context.setDadosProposta({
      //         ...context.propostaState,
      //         etapa: 'vigencia',
      //       });
      //     }
      //   },
      // };

      // // obs: seria melhor no if ternário, porém o eslint esta barrando
      // const vigenciaBeforePlano = this.propostaState.plano && !this.propostaState.plano.idProdutoFatura;
      // if (vigenciaBeforePlano) {
      //   handleProposta.redirectStepVigencia(this);
      // } else {
      //   handleProposta.redirectStepPlano(this);
      // }

      try {
        this.loadingSalvar = true;
        // if (this.form.operadora === 'SULAMÉRICA SAÚDE' && !this.propostaState.possuiPortabilidade) {
        //   const cpf = this.propostaState.titular.cpf
        //   await propostaService.verificarBloqueioSas(cpf)
        // }
        await this.salvarDados(this.form.operadora);

        this.$emit('next');
      } catch (error) {
        // if (error.message === 'Cliente bloqueado na operadora') {
        //   this.$root.$snackBar.open({
        //     color: 'error',
        //     message: 'Não é possível seguir com a operadora selecionada, verifique com seu gestor comercial.',
        //   });            
        // } else {
          this.$root.$snackBar.open({
            color: 'error',
            message: error.message || 'Erro ao salvar dados, tente novamente mais tarde',
          });
        // }
      } finally {
        this.loadingSalvar = false;
      }
    },
  },
  created() {
    const etapasOperadosIsDisabled = ["inclusao_beneficiarios"];
    if(etapasOperadosIsDisabled.includes(this.PMEState.etapa) && this.PMEState.totalPropostas > 0) {
      this.isDisabled = true;
    }
    this.getOperadoras();
  },
};
</script>

<style lang="scss" scoped>
.header-height {
  padding: 20px;
  height: 70px;
}
</style>
