<template>
  <div>
    <v-app-bar color="primary" dark fixed>
      <v-container class="d-flex align-center pa-0">
        <v-btn class="mr-3" small icon dark @click="$emit('back')">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>

        <v-toolbar-title class="mt-1">
          <div>
            <h1 class="title font-weight-bold mb-1 title" style="line-height: 1">PME</h1>
            <h3 class="caption ma-0" style="line-height: 1">Dados contratação</h3>
          </div>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn small icon dark @click="$emit('openmenu')">
          <v-icon>mdi-menu</v-icon>
        </v-btn>
      </v-container>
    </v-app-bar>
    <div class="header-height"></div>
    <v-container class="pa-5 pt-0">
      <v-row justify="center">
        <v-col class="col-12 col-md-6">
          <div class="d-flex justify-end">
            <span class="caption font-weight-bold primary--text">* Campos obrigatórios</span>
          </div>
          <validation-observer tag="form" ref="form" class="mb-10" @submit.prevent="submit" autocomplete="off">
            <v-row>
              <v-col class="pb-0">
                <v-expansion-panels tile flat class="accordion" multiple>
                  <!-- Dados Empresa -->
                  <validation-observer ref="dadosEmpresa" slim v-slot="{ valid, failed }">
                    <v-expansion-panel>
                      <v-expansion-panel-header class="primary--text subtitle-1 font-weight-bold pa-0 pt-4 pb-3" style="min-height: auto; justify-content: space-between">
                        <div class="d-flex align-center">
                          Dados da empresa
                          <v-spacer></v-spacer>
                          <v-icon v-if="valid" color="green" size="25" class="mr-2">mdi-check-circle</v-icon>
                          <v-icon v-else-if="failed" color="red" size="25" class="mr-2">mdi-alert-circle</v-icon>
                        </div>
                        <template v-slot:actions>
                          <v-icon color="primary" size="30">$expand</v-icon>
                        </template>
                      </v-expansion-panel-header>
                      <v-divider></v-divider>
                      <v-expansion-panel-content class="pt-4" eager>
                        <base-text-field rules="required|cnpj" id="cnpj" name="'CNPJ'" label="CNPJ *" v-mask="'##.###.###/####-##'" v-model="form.dadosEmpresa.cnpj" outlined :loading="loadingCnpj"></base-text-field>
                        <base-text-field rules="required" id="razaoSocial" name="'Razão Social'" label="Razão Social *" v-model="form.dadosEmpresa.razaoSocial" outlined :disabled="flagCnpjCarregado"></base-text-field>
                        <base-text-field rules="required" id="nomeFantasia" name="'Nome Fantasia'" label="Nome Fantasia *" v-model="form.dadosEmpresa.nomeFantasia" outlined></base-text-field>
                        <base-text-field
                          id="inscricaoMunicipal"
                          name="'Inscrição Municipal'"
                          label="Inscrição Municipal"
                          v-model="form.dadosEmpresa.inscricaoMunicipal"
                          outlined
                        ></base-text-field>
                        <base-text-field rules="required" id="cnae" name="'Cnae'" label="Cnae *" v-model="form.dadosEmpresa.cnae" outlined :disabled="flagCnpjCarregado"></base-text-field>
                        <base-text-field
                          rules="required|date"
                          id="dataAbertura"
                          name="'Data de Abertura'"
                          label="Data de Abertura *"
                          v-mask="'##/##/####'"
                          v-model="form.dadosEmpresa.dataAbertura"
                          outlined
                          :disabled="flagCnpjCarregado"
                        ></base-text-field>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </validation-observer>
                  <!-- Endereço da Empresa -->
                  <validation-observer ref="dadosEndereco" slim v-slot="{ valid, failed }">
                    <v-expansion-panel>
                      <v-expansion-panel-header class="primary--text subtitle-1 font-weight-bold pa-0 pt-4 pb-3" style="min-height: auto; justify-content: space-between">
                        <div class="d-flex align-center">
                          Endereço da empresa
                          <v-spacer></v-spacer>
                          <v-icon v-if="valid" color="green" size="25" class="mr-2">mdi-check-circle</v-icon>
                          <v-icon v-else-if="failed" color="red" size="25" class="mr-2">mdi-alert-circle</v-icon>
                        </div>
                        <template v-slot:actions>
                          <v-icon color="primary" size="30">$expand</v-icon>
                        </template>
                      </v-expansion-panel-header>
                      <v-divider></v-divider>
                      <v-expansion-panel-content class="pt-4" eager>
                        <async-cep-comercial :cep="form.enderecoEmpresa.cep" :loadingMounted="loadingCep" v-slot="{ loading: loadingRequestCep }" @success="successCep($event)" @error="errorCep()">
                          <base-text-field
                            rules="required|min:9"
                            id="cep"
                            name="'CEP'"
                            label="CEP *"
                            v-mask="'#####-###'"
                            v-model="form.enderecoEmpresa.cep"
                            :loading="loadingRequestCep"
                            outlined
                          ></base-text-field>
                        </async-cep-comercial>
                        <base-text-field rules="required" id="UF" name="'UF'" label="UF *" v-mask="'AA'" v-model="form.enderecoEmpresa.estado" :disabled="flagCepCarregado" outlined></base-text-field>
                        <base-text-field
                          rules="required"
                          id="municipio"
                          name="'Município'"
                          label="Município *"
                          v-model="form.enderecoEmpresa.cidade"
                          :disabled="flagCepCarregado"
                          outlined
                        ></base-text-field>
                        <base-text-field rules="required" id="bairro" name="'Bairro'" label="Bairro *" v-model="form.enderecoEmpresa.bairro" :disabled="flagCepCarregado" outlined></base-text-field>
                        <base-text-field
                          rules="required"
                          id="logradouro"
                          name="'Logradouro'"
                          label="Logradouro *"
                          v-model="form.enderecoEmpresa.logradouro"
                          :disabled="flagCepCarregado"
                          outlined
                        ></base-text-field>
                        <base-text-field rules="required" id="numero" name="'Número'" label="Número *" v-model="form.enderecoEmpresa.numero" outlined></base-text-field>
                        <base-text-field id="complemento" name="'Complemento'" label="Complemento" v-model="form.enderecoEmpresa.complemento" outlined></base-text-field>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </validation-observer>
                  <!-- Representante da Empresa -->
                  <validation-observer ref="representanteEmpresa" slim v-slot="{ valid, failed }">
                    <v-expansion-panel>
                      <v-expansion-panel-header class="primary--text subtitle-1 font-weight-bold pa-0 pt-4 pb-3" style="min-height: auto; justify-content: space-between">
                        <div class="d-flex align-center">
                          Representante da empresa
                          <v-spacer></v-spacer>
                          <v-icon v-if="valid" color="green" size="25" class="mr-2">mdi-check-circle</v-icon>
                          <v-icon v-else-if="failed" color="red" size="25" class="mr-2">mdi-alert-circle</v-icon>
                        </div>
                        <template v-slot:actions>
                          <v-icon color="primary" size="30">$expand</v-icon>
                        </template>
                      </v-expansion-panel-header>
                      <v-divider></v-divider>
                      <v-expansion-panel-content class="pt-4" eager>
                        <base-text-field rules="required|special_characters" id="nomeCompleto" name="'Nome Completo'" label="Nome Completo *" v-model="form.representanteEmpresa.nomeCompleto" outlined></base-text-field>
                        <base-text-field rules="required|cpf" id="cpf" name="'CPF'" label="CPF *" v-mask="'###.###.###-##'" v-model="form.representanteEmpresa.cpf" outlined></base-text-field>
                        <base-text-field
                          rules="required|date"
                          id="dataNascimento"
                          name="'Data de Nascimento'"
                          label="Data de Nascimento *"
                          v-mask="'##/##/####'"
                          v-model="form.representanteEmpresa.dataNascimento"
                          outlined
                        ></base-text-field>
                        <async-tipo-sexo v-slot="{ loading: loadingTipoSexo, data }">
                          <base-wrapper-dialog-options
                            v-if="!loadingTipoSexo"
                            headerTitle="Sexo"
                            :options="data"
                            @select="$set(form.representanteEmpresa, 'sexo', $event.id)"
                            value-key="id"
                            label-key="nome"
                            ref="dialogSexo"
                            :optionSelected="form.representanteEmpresa.sexo"
                            :loading="loadingTipoSexo"
                          >
                            <template v-slot:activador="{ on }">
                              <base-text-field
                                readonly
                                rules="required"
                                placeholder="Clique aqui para selecionar"
                                id="sexo"
                                name="'Sexo'"
                                :data-test-id="'selecionar-sexo'"
                                v-on="on"
                                :value="form.representanteEmpresa.sexo"
                                label="Sexo *"
                                outlined
                                append-icon="mdi-chevron-right"
                                :loading="loadingTipoSexo"
                              ></base-text-field>
                            </template>
                          </base-wrapper-dialog-options>
                        </async-tipo-sexo>
                        <base-text-field rules="required|email" id="email" name="'E-mail'" label="E-mail *" v-model="form.representanteEmpresa.email" outlined></base-text-field>
                        <base-text-field
                          rules="ddd|phone"
                          id="telefone"
                          name="'Telefone'"
                          label="Telefone"
                          v-mask="['## #####-####', '## ####-####']"
                          v-model="form.representanteEmpresa.telefone"
                          outlined
                        ></base-text-field>
                        <base-text-field
                          rules="required|ddd|phone"
                          id="celular"
                          name="'Celular'"
                          label="Celular *"
                          v-mask="['## #####-####', '## ####-####']"
                          v-model="form.representanteEmpresa.celular"
                          outlined
                        ></base-text-field>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </validation-observer>
                </v-expansion-panels>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn large type="submit" elevation="10" block color="primary" class="secundaryBtn--text" :loading="loading"> Salvar e continuar </v-btn>
              </v-col>
            </v-row>
          </validation-observer>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import empresaService from "@/services/empresaService";
import BaseWrapperDialogOptions from "@/components/BaseWrapperDialogOptions.vue";
import BaseTextField from "@/components/BaseTextField.vue";
import AsyncTipoSexo from "@/components/AsyncTipoSexo.vue";
import AsyncCepComercial from "@/components/AsyncCepComercial.vue";

export default {
  name: "Empresa",
  components: {
    BaseWrapperDialogOptions,
    BaseTextField,
    AsyncCepComercial,
    AsyncTipoSexo,
  },
  data() {
    return {
      loading: false,
      loadingCep: false,
      flagCepCarregado: false,
      loadingCnpj: false,
      flagCnpjCarregado: false,
      keyTipoSexo: 0,
      form: {
        dadosEmpresa: {
          razaoSocial: null,
          cnpj: null,
          inscricaoMunicipal: null,
          dataAbertura: null,
          nomeFantasia: null,
          cnae: null,
        },
        enderecoEmpresa: {
          cep: null,
          bairro: null,
          cidade: null,
          estado: null,
          logradouro: null,
          complemento: null,
          numero: null,
        },
        representanteEmpresa: {
          nomeCompleto: null,
          dataNascimento: null,
          sexo: null,
          cpf: null,
          email: null,
          telefone: null,
          celular: null,
        },
      },
    };
  },
  computed: {
    ...mapState({
      PMEState: (state) => state.PME,
    }),
  },
  created() {
    this.setPreviousData();
  },
  watch: {
    "form.dadosEmpresa.cnpj": {
      handler(cnpj) {
        if (cnpj.length === 18) {
          this.getCNPJData(cnpj);
        }
      },
    },
  },
  methods: {
    ...mapActions({
      setEmpresa: "PME/setEmpresa",
      setGUID: "PME/setGUID",
      setID: "PME/setID",
    }),
    setPreviousData() {
      if(this.PMEState.empresa) {
        this.form.dadosEmpresa = {
          ...this.form.dadosEmpresa,
          ...this.PMEState.empresa.dadosEmpresa,
          dataAbertura: this.PMEState.empresa.dadosEmpresa.dataAbertura ? this.PMEState.empresa.dadosEmpresa.dataAbertura.split("-").reverse().join("/") : null,
        }
        this.form.enderecoEmpresa = {
          ...this.form.enderecoEmpresa,
          ...this.PMEState.empresa.enderecoEmpresa
        }
        this.form.representanteEmpresa = {
          ...this.form.representanteEmpresa,
          ...this.PMEState.empresa.representanteEmpresa,
          dataNascimento: this.PMEState.empresa.representanteEmpresa.dataNascimento ? this.PMEState.empresa.representanteEmpresa.dataNascimento.split("-").reverse().join("/") : null
        }
      } 
    },
    async salvarDados(body) {
      try {
        if (this.PMEState.id) {
          await empresaService.atualizarEmpresa(
            this.PMEState.id,
            {
              corretora: this.PMEState.corretora,
              ...body,
            },
          );
        } else {
          const res = await empresaService.incluirEmpresa({
            corretora: this.PMEState.corretora,
            ...body,
          });
          this.setGUID(res.data.guid);
          this.setID(res.data.id);
        }
        this.setEmpresa({ ...body });
        this.$router.push({ name: 'areaLogada.PME', params: { guid: this.PMEState.guid } });
      } catch (error) {
        throw new Error(error.message || "Erro ao salvar dados.");
      }
    },
    async submit() {
      const isValid = await this.$refs.form.validate();
      if (!isValid) return;
      // Salva os dados
      try {
        this.loading = true;
        const body = {
          dadosEmpresa: {
            ...this.form.dadosEmpresa,
            cnpj: this.form.dadosEmpresa.cnpj.replace(/\D/g, ""),
            dataAbertura: this.form.dadosEmpresa.dataAbertura.split("/").reverse().join("-"),
          },
          enderecoEmpresa: {
            ...this.form.enderecoEmpresa,
            cep: this.form.enderecoEmpresa.cep.match(/\d/g).join(""),
            complemento: this.form.enderecoEmpresa.complemento ? this.form.enderecoEmpresa.complemento : null,
          },
          representanteEmpresa: {
            ...this.form.representanteEmpresa,
            dataNascimento: this.form.representanteEmpresa.dataNascimento.split("/").reverse().join("-"),
            cpf: this.form.representanteEmpresa.cpf.match(/\d/g).join(""),
            telefone: this.form.representanteEmpresa.telefone ? this.form.representanteEmpresa.telefone.match(/\d/g).join("") : null,
            celular: this.form.representanteEmpresa.celular.match(/\d/g).join(""),
          },
        };
        await this.salvarDados(body);
        this.$emit("next");
      } catch (error) {
        this.$root.$snackBar.open({
          color: "error",
          message: error.message || "Erro ao salvar dados, tente novamente mais tarde",
        });
      } finally {
        this.loading = false;
      }
    },
    async getCNPJData(cnpj) {
      try {
        this.loadingCnpj = true;
        const { data } = await empresaService.buscarDadosCNPJZooxSmart(cnpj.replace(/\D/g, ""));
        
        if (!data.razao_social || !data.cod_cnae || !data.data_abertura) {
          throw new Error();
        }

        this.form.dadosEmpresa = {
          ...this.form.dadosEmpresa,
          razaoSocial: data.razao_social,
          nomeFantasia: data.nome_fantasia || null,
          cnae: data.cod_cnae,
          dataAbertura: data.data_abertura.split("-").reverse().join("/")
        };

        this.flagCnpjCarregado = true;

        this.$root.$snackBar.open({
          color: "success",
          message: data.razao_social,
        });
      } catch (error) {
        // this.form.dadosEmpresa = {
        //   ...this.form.dadosEmpresa,
        //   razaoSocial: null,
        //   nomeFantasia: null,
        //   cnae: null,
        //   dataAbertura: null
        // };
        this.flagCnpjCarregado = false;
        this.$root.$snackBar.open({
          color: "error",
          message: error.message || "Cnpj não encontrado.",
        });
      } finally {
        this.loadingCnpj = false;
      }
    },
    successCep(data) {
      this.form.enderecoEmpresa = {
        ...this.form.enderecoEmpresa,
        bairro: data.bairro,
        cidade: data.cidade,
        estado: data.estado,
        logradouro: data.logradouro,
        complemento: null,
        numero: null,
      };
      this.loadingCep = false;
      this.flagCepCarregado = true;
    },
    errorCep() {
      this.form.enderecoEmpresa = {
        ...this.form.enderecoEmpresa,
        bairro: null,
        cidade: null,
        estado: null,
        logradouro: null,
        complemento: null,
        numero: null,
      };
      this.loadingCep = false;
      this.flagCepCarregado = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.header-height {
  padding: 20px;
  height: 70px;
}
</style>
